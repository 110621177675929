import React from "react"
import MainLayout from "../../components/MainLayout"
import SEO from "../../components/seo"
import { Helmet } from "react-helmet"
import styled from "styled-components"
import ImagePicker from "../../components/ImagePicker"
import ProductDescription from "../../components/products/ProductDescription"
import IndexSection3 from "../../components/IndexSection3"

import Image1Large from "../../images/xs-compact-17d-traction-w500.jpg"

const ProductLayout = styled.div`
  #products-layout {
    padding-top: 150px;
    display: grid;
    align-items: center;
    justify-items: center;
    grid-template-columns: 50% 50%;
    /* align-items: baseline; */

    @media (max-width: 1130px) {
      grid-template-columns: 100%;
      padding-top: 100px;
    }
  }
`

const Xs20PaCompactPage = () => {
  return (
    <>
      <SEO title="XS17 Compact Disk Traction | Timberline Cleaning Equipment" />
      <Helmet>
        {/* <title>XS Compact Scrubber | Timberline Cleaning Equipment</title> */}
        <description>
          The XSC 17" Traction Floor Scrubber cleans in hard to reach areas with
          its small footprint. Traction motor for operator ease and transporting
          up ramps. Used Schools, Hospitals, Malls, Food & Beverage, and more.
          The 17-inch (43cm) disk style scrub brush is installed and removed
          without tools to allow ease when switching between different brushes
          or pads, depending upon the application’s needs. The squeegee blades
          can be rotated or flipped without tools.
        </description>
      </Helmet>
      <MainLayout>
        <ProductLayout>
          <div id="products-layout">
            <ImagePicker
              border="none"
              image1Large={Image1Large}
              mobileLink="#demo-section"
              mobileTitle="XSS17 Disk Traction"
            />
            <div>
              <ProductDescription
                productTitle="XSC17 Compact Disk Traction"
                subHeading="Walk-Behind Small Floor Scrubbers"
                modelNum="XSC17D"
                productDescription='The XSC 17" Traction Floor Scrubber cleans in hard to reach areas with its small footprint.  Traction motor for operator ease and transporting up ramps.  Used Schools, Hospitals, Malls, Food & Beverage, and more.  The 17-inch (43cm) disk style scrub brush is installed and removed without tools to allow ease when switching between different brushes or pads, depending upon the application’s needs.  The squeegee blades can be rotated or flipped without tools.'
                link="#demo-section"
                list1="Constructed robustly — steel frame construction and durable components "
                list2="Simplify maintenance with easy access to the battery and recovery tank"
                list3="Traction drive speed controlled with easy to use Drive Speed Control thumb dial"
                list4="Easily operate and maneuver with push button forward and toggle reverse"
                list5="Clean any hour of the day with approved sound levels < 67 dBA"
                list6="Increase productivity with over 2 hours of run time between dump and refill"
                list7="Off-the-shelf toggles, switches, gauges, and mechanical levers to allow easy operator training and potential repairs"
                brochure="../../TL-XSS-XSC-BROCHURE-V20073.pdf"
                tech="../../XS-Timberline-Specs.pdf"
                parts="../../t-xs-c-p-en.pdf"
                operator="../../t-xs-compact-op-en.pdf"
                displayHood="none"
                showPM="none"
                mtLink="https://www.dropbox.com/s/evrcuco63ko0478/daily-weekly-maintenance-3rd-v21306.pdf?dl=0"
              />
            </div>
          </div>
          <div id="demo-section">
            <IndexSection3 title="Request a Free Demo at your Facility" />
          </div>
        </ProductLayout>
      </MainLayout>
    </>
  )
}

export default Xs20PaCompactPage
